import React from 'react';
import RootElement from './src/components/root-element';
import "@popperjs/core/dist/umd/popper.min"
import "bootstrap/dist/css/bootstrap.min.css"
import 'animate.css'
import "bootstrap-icons/font/bootstrap-icons.css"
import 'boxicons';
import 'boxicons/css/boxicons.css'
import 'aos/dist/aos.css'
import './src/css/global.css'
import 'font-awesome/css/font-awesome.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";

export const wrapRootElement = ({ element }) => {
    return <RootElement>{element}</RootElement>;
};